
  
  export const webPortofolio = [
    {
      id: 1,
      title: "FacebookClone",
      img: "https://cdn.dribbble.com/users/5031392/screenshots/15467520/media/c36b3b15b25b1e190d081abdbbf947cf.png?compress=1&resize=1200x900",
    }
  ];
  
  export const mobilePortofolio = [
    {
      id: 1,
      title: "Mobile Social Media App",
      img:
        "https://cdn.dribbble.com/users/2597268/screenshots/15468893/media/44313553d73ba41580f1df69749bba28.jpg?compress=1&resize=1200x900",
    }
  ];
  
 